.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  min-height: 100%;
}

.content {
  margin: 2rem;
}

.accordion {
  margin-top: 36px;
}