.main {
  background: linear-gradient(135deg, #fff, var(--color-primary-green-light));
  padding: 20px 20px 50px;
  min-height: 100%;
}

.content {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.card {
  width: calc(100% - 40px);
  margin: 2rem auto;
}

.cardTitle {
  margin-bottom: 2rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.input {
  margin-bottom: 30px;
}

.chip {
  margin: 4px 6px;
}

.chip[data-status="inactive"] {
  opacity: .75;
}

.icon {
  padding-left: 6px;
}