.placeholder {
  font-style: italic;
  color: var(--color-gray);
}

.pruebaRow {
  background: rgba(var(--color-primary-orange-rgb), 0.4);
}

.mainRowCell {
  border: 0;
}

.mainRowCell[data-visibility='invisible'] {
  display: none;
}

.alumno {
  display: flex;
  align-items: center;
}

.alumno > i {
  margin-right: 10px;
}

.alumnoLink {
  color: var(--color-dark);
  text-decoration: none;
}

.alumnoLink:hover {
  color: var(--color-primary-green);
  cursor: pointer;
}

.additionalRow {
  padding: 0;
}

.extraInfo {
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.extraInfo > i {
  margin-right: 0.5rem;
}

.observaciones {
  padding: 2rem 4rem;
}