.label {
  display: flex;
  align-items: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 500;
}

.alert {
  margin: 10px 20px;
}